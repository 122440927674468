<template>
    <div>
        <el-row>
            <el-col>
                <el-form :inline='true'>
                    <el-form-item label="是否启用:">
                        <el-select v-model="filters.isEnable" clearable>
                            <el-option
                            v-for="item in optionsData"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
                    </el-form-item>
                </el-form>             
            </el-col>
        </el-row>
        <!--列表-->
        <el-table
        :data="listData"
        highlight-current-row
        v-loading="listLoading"
        border=""
        @current-change="selectCurrentRow"
        style="width: 95%;"
        >
            <el-table-column type="index" width="60" align="center" label="序号"></el-table-column>
            <el-table-column prop="CreateTime" align="center" label="创建时间" width show-overflow-tooltip></el-table-column>
            <el-table-column prop="IsEnable" align="center" label="是否当前启用" min-width="100">
                <template slot-scope='scope'>
                    <el-tag v-if='scope.row.IsEnable == 1' type='success'>是</el-tag>
                    <el-tag v-else type='danger'>否</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="CheckUnit" align="center" label="监督检查单位(家、次)" width show-overflow-tooltip></el-table-column>
            <el-table-column prop="RandomCheckUnit" align="center" label="双随机检查单位(家)" width show-overflow-tooltip></el-table-column>
            <el-table-column prop="" align="center" label="内容" width show-overflow-tooltip>
                <template slot-scope='scope'>                 
                    <el-button type="text" @click="handleClick(scope.row)">查看</el-button>
                </template>
            </el-table-column>
            <el-table-column prop='IsEnable' align='center' label='启用状态' min-width="100">
                <template slot-scope='scope'>                 
                    <el-tag v-if='scope.row.IsEnable == 1' type='success'>启用</el-tag>
                    <el-tag v-else type='danger'>禁用</el-tag>
                </template>
            </el-table-column>
        </el-table>
        <!--底部工具条-->
        <el-col :span="24" class="pageBar">
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="pages.pageIndex"
                :page-sizes="pages.pageArr"
                :page-size="pages.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="pages.dataCount">
            </el-pagination>
        </el-col>
        <!--新增/编辑界面-->
        <el-dialog
            :title="addOrEdi"
            :visible.sync="addFormVisible"
            v-model="addFormVisible"
            :close-on-click-modal="false"
            @close='handleClose'
            width="60%"
        >
            <el-form label-width="150px" ref="formData" :inline='true'>
                <el-form-item v-for="(item,index) in GDcityData" :label="item.Name || item.City" :key="index">
                    <div>
                        <el-input v-model='item.CheckUnit' placeholder="请输入数字" type="text" onkeyup="value=value.replace(/[^\d]/g,'')"></el-input>
                        <div class="inputTipsStr">监督检查单位(家、次)</div>
                    </div>
                    <div>
                        <el-input v-model='item.RandomCheckUnit' placeholder="请输入数字" type="text" onkeyup="value=value.replace(/[^\d]/g,'')"></el-input>
                        <div class="inputTipsStr">双随机检查单位(家)</div>
                    </div>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="addFormVisible = false">取消</el-button>
                <el-button type="primary" @click.native="addSubmit" :loading="addLoading" v-if="addOrEdi != '查看'">确定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import Toolbar from "../../components/Toolbar"
import { getButtonList } from "../../promissionRouter";
import { getReportDataSetting,deletedReportDataSettingByBatch,getAreaDictionaryList,getReportDataSettingByBatch,saveReportDataSettingByBatch,isEnableReportDataSettingForBatch } from '@/api/api'
export default {
    components: {Toolbar},
    data() {
        return{
            filters: {
                informationType: '',
                isEnable:''
            },
            buttonList: [],
            listData: [],
            listLoading: false,
            GDcityData: [],


            optionsData:[
                {value:true,label:'启用'},
                {value:false,label:'禁用'}
            ],
            addFormVisible: false,
            addLoading: false,
            addOrEdi: "查看",
            currentRow:null, // 当前行
            //关于分页的obj
            pages: {
                pageSize: 20,//默认每页条数
                pageArr: [10, 20, 30, 40],//默认每页条数选择
                pageIndex: 1, //默认进入页
                dataCount: 20, //默认总页数
            },
        }
    },
    methods: {
        // 查询
        getReportDataFun() {
            this.pages.pageIndex = 1
            this.getListData();
        },
        // 新增
        handleAdd() {
            this.addOrEdi = "新增"
            this.addFormVisible = true;
            this.getGDcityDataFun();
        },
        // 编辑
        handleEdit(){
            this.addOrEdi = "编辑"
            var row = this.currentRow
            this.addFormVisible = true;
            if(row == null){
                this.$message({
                    message:'请选择编辑的一行数据',
                    type:'warning'
                })
            }else{
                this.getBackViewDataFun(row)
            }
        },
        // 查看
        handleClick(val) {
            this.addOrEdi = "查看"
            this.addFormVisible = true;
            this.getBackViewDataFun(val)
        },
        // 查看/编辑数据回显
        getBackViewDataFun(val) {
            var params = {
                batchId: val.Batch
            }
            getReportDataSettingByBatch(params).then(res => {
                var result = res.data
                if(result.Success) {
                    this.GDcityData = result.Response
                }
            })
        },
        // 获取广东各个市数据
        getGDcityDataFun() {
            var params = {
                parentId: 1919,
            }
            getAreaDictionaryList(params).then(res => {
                var result = res.data
                if(result.Success){
                    result.Response.forEach((item,index) => {
                        item.CheckUnit = ""
                        item.RandomCheckUnit = ""
                    })
                    this.GDcityData = result.Response
                }
            })
        },
        // 删除
        handleDel(){
            var row = this.currentRow
            if(row == null){
                this.$message({
                    message:'请选择要删除的一行数据',
                    type:'warning'
                })
            }else{
                this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    var params = {
                        batchId: row.Batch
                    }
                    deletedReportDataSettingByBatch(params).then(res => {
                        if(res.data.Success){
                            this.$message({
                                message:res.data.Message,
                                type:'success'
                            })
                            this.getListData()
                        }else{
                            this.$message({
                                message:res.data.Message,
                                type:'error'
                            })
                        }
                    }).catch(() => {})
                }).catch(() => {})
            }
        },
        // 启用/禁用简报数据设置
        EnableOrDisableFun(){
            var row = this.currentRow
            if(row == null){
                this.$message({
                    message:'请选择要启用/禁用的一行数据',
                    type:'warning'
                })
            }else{
                this.$confirm('确定启用/禁用?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    var params = {
                        ID: row.Batch,
                        IsEnabled: !row.IsEnable
                    }
                    isEnableReportDataSettingForBatch(params).then(res => {
                        if(res.data.Success){
                            this.$message({
                                message:res.data.Message,
                                type:'success'
                            })
                            this.getListData()
                        }else{
                            this.$message({
                                message:res.data.Message,
                                type:'error'
                            })
                        }
                    }).catch(() => {})
                }).catch(() => {})
            }
        },
        callFunction(item) {
            this[item.Func].apply(this, item);
        },
        // 获取数据
        getListData() {
            var params = {
                pageIndex: this.pages.pageIndex,
                pageSize: this.pages.pageSize,
                isEnable: this.filters.isEnable,
            }       
            this.listLoading = true;
            getReportDataSetting(params).then(res => {
                var result = res.data
                if(res.data.Success){
                    this.pages.dataCount = result.Response.DataCount
                    this.pages.pageIndex = result.Response.PageIndex
                    if(result.Response.PageSize > 0){
                        this.pages.pageSize = result.Response.PageSize
                    }
                    this.listData = res.data.Response.Data
                }
                this.listLoading = false
            })
        },
        // 点击table某一行
        selectCurrentRow(val) {
            this.currentRow = val
        },
        // 提交表单
        addSubmit() {
            var isAgreeNext = 0
            var newArr = []
            this.GDcityData.forEach(item => {
                if(item.CheckUnit || item.RandomCheckUnit) {
                    var newObj = {}
                    newObj.ProvinceID = item.ParentId || 1919
                    newObj.CityID = item.ID || item.CityID
                    newObj.CheckUnit = item.CheckUnit
                    newObj.RandomCheckUnit = item.RandomCheckUnit
                    if(this.addOrEdi == "编辑") {
                        newObj.Id = item.Id
                    } else {
                        newObj.Id = 0
                    }
                    newArr.push(newObj)
                } else {
                    isAgreeNext++
                }
            })
            if(isAgreeNext) {
                this.$message.warning("请输入所有必填项！")
                return
            }
            var params = newArr
            this.addLoading = true
            saveReportDataSettingByBatch(params).then(res => {
                var result = res.data
                if(result.Success) {
                    this.$message.success(result.Message)
                    this.addFormVisible = false
                    this.getListData()
                } else {
                    this.$message.error(result.Message)
                }
                this.addLoading = false
            })
        },
        // 分页
        handleCurrentChange(val) {
            this.pages.pageIndex = val;
            this.getListData();
        },
        handleSizeChange(val){    
            this.pages.pageSize = val;             
            this.getListData();
        },
        // 表单清空
        handleClose(){
            this.$refs['formData'].resetFields()
        },
        // 时间转换
        dateFormat(format,date){
            let ret = ''
            date = new Date(date)
            const option = {
                'Y+':date.getFullYear().toString(),  
                'm+':(date.getMonth() + 1).toString(),
                'd+':date.getDate().toString(),
                'H+':date.getHours().toString(),
                'M+':date.getMinutes().toString(),
                'S+':date.getSeconds().toString()
            }
            for(let k in option){
                ret = new RegExp('('+k+')').exec(format)
                if(ret){
                    format = format.replace(
                        ret[1],
                        ret[1].length == 1? option[k] :option[k].padStart(ret[1].length,'0')
                    )
                }
            }
            return format
        }
    },
    mounted() {
        this.getListData();
        let routers = window.localStorage.router
        ? JSON.parse(window.localStorage.router)
        : [];
        this.buttonList = getButtonList(this.$route.path, routers);
    },
}
</script>

<style lang="stylus" scoped>
    .viwesTitle {
        font-weight bold;
        text-align center;
        font-size 18px;
        margin-bottom 15px;
    }
    .viwesContent {
        text-indent:2em;
        font-size 16px;
        line-height 26px;
        text-align justify;
    }
    .el-textarea>>>.el-textarea__inner {
        min-height 150px!important;
    }
    // .pageBar{
    //     margin-top:15px!important;
    // }
    .avatar {
        width: 100%;
        height: 100%;
        display: block;
    }
    .pictureViews img{
        width 130px!important;
        max-height 150px!important;
        margin-right 10px;
    }
    .inputTipsStr {
        color: #888;
    }
</style>